import React from "react"
import { graphql } from "gatsby"
import '../assets/css/style.css';
import Layout from "../components/layout";
import SEO from "../components/seo";
import DocSidebar from "../components/DocsComponents/sidebar";
import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader';
deckDeckGoHighlightElement();

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
      <Layout pageId="docs" logoText="Docs" >
            <SEO 
              description={frontmatter.metaDescription}
              title={`${frontmatter.title}`} 
              author="Athul Suresh"
              image={frontmatter.featImg} />

    <div>

        <div className="blog-post-container pt-14">
            <div className="blog-post py-2 flex">
                <DocSidebar pageId={frontmatter.pageCode} />
                <div className="md:ml-64 flex-1">
                    <div className="max-w-4xl mx-auto py-8 px-6">
                        <h1 className="font-bold mb-1 text-gray-800">{frontmatter.title}</h1>
                        <small className="text-sm text-gray-400">{frontmatter.date}</small>
                        <img className="my-8 w-full" src={frontmatter.featImg}></img>
                        <div
                            className="blog-post-content markdown"
                            dangerouslySetInnerHTML={{ __html: html }}
                        />
                    </div>
                </div>
                
                
            </div>
        </div>

    </div>
    </Layout>
    
  )
}
export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        featImg
        pageCode
        metaDescription
      }
    }
  }
`
